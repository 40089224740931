import React, { Component } from 'react'
import { Link } from 'react-router'
import classnames from 'classnames'
import moment from 'moment-timezone'
import momentFi from 'moment/locale/fi'
import { connect } from 'react-redux'
import Sticky from 'react-stickynode'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import MenuProfile from '../../components/menu/MenuProfile'
import BrandLogo from './BrandLogo'
import MainNavigation from './MainNavigation'
import CurrentWeatherNavBar from './weather/CurrentWeatherNavBar'
import MenuNamedays from './MenuNamedays'
import MenuSearchToggle from './MenuSearchToggle'
import './menu.pcss'
import { selectIsAlmaTunnusEmailVerified } from '../../selectors/almaTunnusSelectors'
import { selectUserName } from '../../selectors/userSelector'
import { openSearch } from '../../views/Search/searchActions'

moment.updateLocale('fi', momentFi)

@connect((state) => {
  const { brandLogo, user } = state
  return {
    brandLogoImage: brandLogo.get('image'),
    frontPage: user.get('frontPage'),
    emailVerified: selectIsAlmaTunnusEmailVerified(state),
    userName: selectUserName(state),
  }
}, {})

export default class Menu extends Component {

  static propTypes = exact({
    brandLogoImage: PropTypes.string,
    frontPage: PropTypes.string.isRequired,
    emailVerified: PropTypes.bool.isRequired,
    userName: PropTypes.string,
    timestamp: PropTypes.number.isRequired,
    searchOpen: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
  })

  render() {
    const {
      timestamp,
      searchOpen,
      isOpen,
      loggedIn,
      brandLogoImage,
      path,
      frontPage,
      userName,
      emailVerified,
    } = this.props

    let name
    if (loggedIn) {
      name = userName
    } else if (emailVerified && !loggedIn ) {
      name = '. . .'
    }

    return (
      <Sticky className={ classnames('top-menu', {'blur': isOpen}) } innerZ={ 22 }>
        <div className={ classnames('top-menu__container top-menu__container_outer') }>
          <div className='top-menu__container top-menu__container_inner outer-container'>

            <div className='menu-bar'>
              <div className='menu-bar__column menu-bar__column_logo'>
                <BrandLogo
                  image={ brandLogoImage }
                  frontPage={ frontPage }
                  path={ path }
                />
              </div>

              <div className='menu-bar__column menu-bar__column_pages'>
                <MainNavigation />
              </div>

              <div className='menu-bar__column menu-bar__column_search'>
                <MenuSearchToggle searchOpen={ searchOpen } onSearchOpen={ this.handleSearchOpen } />
              </div>

              <div className='menu-bar__column menu-bar__column_stats'>
                <MenuNamedays timestamp={ timestamp } />
              </div>

              <div className='menu-bar__column menu-bar__column_weather'>
                <Link to='/saa' title='10 vrk sääennusteeseen'>
                  <CurrentWeatherNavBar />
                </Link>
              </div>

              <div className='menu-bar__column menu-bar__column_login'>
                <MenuProfile
                  loggedIn={ loggedIn }
                  userName={ name }
                />
              </div>

            </div>
          </div>
        </div>
      </Sticky>
    )
  }

  handleSearchOpen = () => {
    this.props.dispatch(openSearch())
  }

  handleToggleMenu = () => {
    this.toggleMenu()
  }
}
