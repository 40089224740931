import { createMiddleware } from 'redux-beacon'
import GoogleTagManager from '@redux-beacon/google-tag-manager'
import { LOCATION_CHANGE } from 'react-router-redux'
import moment from 'moment-timezone'

import { selectItemById } from '../selectors/itemsSelector'
import { selectCategoryHierarchy, selectCategoryById } from '../selectors/categoriesSelector'
import { selectUserLoginStatusGA4 } from '../selectors/userSelector'

import {
  SET_LOGGED_IN_USER,
  LOGOUT_USER_SUCCESS,
  SET_ACTIVE_PRESET_PROFILE,
  SET_NIGHT_MODE_SUCCESS,
} from '../user/userActions'
import {
  FETCH_NEXT_CATEGORY_ITEMS_SUCCESS,
  FETCH_NEXT_ITEMS_SUCCESS,
  FETCH_NEXT_TAG_ITEMS_SUCCESS,
  FETCH_NEXT_USER_ITEMS_SUCCESS,
  ITEM_CLICK,
  ITEM_SHARE_INTENT,
  SET_ITEMS_LANGUAGE,
  ITEM_VOTE_SUCCESS,
} from '../components/item/itemsActions'
import { selectActivePresetProfileName } from '../selectors/profilesSelector'



/**
 * All GA4 dataLayer variables
 */
const dataLayerVariables = [
  'dlv - article_language',
  'dlv - article_tags',
  'dlv - article_paywall',
  'dlv - event_value',
  'dlv - news_category1',
  'dlv - news_category2',
  'dlv - news_category3',
  'dlv - news_media',
  'dlv - news_url',
  'dlv - publish_date',
  'dlv - ui_element',
  'dlv - ab_test_variant',
  'dlv - login_status',
]


/**
 * Return a "fresh" GA4 event where all data layer variables are explicitly set, so that
 * previously pushed values (from previous GA4 events) do not pollute the new event.
 */
function GA4Event(eventName, state, eventData) {
  const eventDataLayer = Object.assign({}, {
    event: 'sendGAEvent',
    'dlv - eventName': eventName,
    'dlv - login_status': selectUserLoginStatusGA4(state),
  }, eventData)
  dataLayerVariables.forEach(variableName => {
    if (eventDataLayer[variableName] == null) {
      eventDataLayer[variableName] = undefined
    }
  })
  return eventDataLayer
}

/**
 * Reads state and creates GA4 data object for itemId
 */
function GetGA4ItemData(state, itemId) {
  const item = selectItemById(state, itemId)
  const tags = item.tags.toJS().join(',')
  const published = moment(item.timestamp * 1000)
  const language = item.language
  const url = item.custom ? '[custom feed, url redacted for privacy]' : item.link
  const paywall = item.paywalled ? 'paid' : 'free'

  let rootCategory = 'oma-syote'
  let subCategory = undefined
  let itemCategory = 'oma-syote'
  if (!item.custom) {
    const category = selectCategoryById(state, item.categoryId)
    const categories = selectCategoryHierarchy(state, category)
    rootCategory = categories.getIn([0, 'slug'])
    subCategory = categories.getIn([1, 'slug'], undefined)
    itemCategory = category.slug
  }

  return {
    'dlv - news_url': url,
    'dlv - news_media': item.source.toLowerCase(),
    'dlv - article_tags': tags,
    'dlv - news_category1': rootCategory,
    'dlv - news_category2': subCategory,
    'dlv - news_category3': itemCategory,
    'dlv - publish_date': published.format('DD/MM/YYYY'),
    'dlv - article_paywall': paywall,
    'dlv - article_language': language,
  }
}



/**
 * Creates a middleware for pushing redux actions to GTM dataLayer (and from there to Google Analytics).
 */
export function getBeaconMiddleware() {
  /**
   * Generate pageview whenever user navigates on the page. GA automatically logs the pageview on the
   * initial page load. Accordingly, we have to skip sending a pageview the first time this code runs.
   */
  let firstPageView = true
  const pushPageView = action => {
    if (firstPageView) {
      firstPageView = false
      return {}
    }
    return {
      event: 'react-pageview',
      pageview: {
        path: action.payload.pathname,
        title: 'Ampparit React Navigation',
      },
    }
  }

  /**
   * Set DataLayer variables 'userId' and 'userHash' on login or change, clear on logout
   */
  const pushUserId = (action, prevState, nextState) => {
    const userId = action.userId ? `${action.userId}` : ''
    const userHash = action.userHash ? `${action.userHash}` : ''
    const loginStatus = selectUserLoginStatusGA4(nextState)
    return { userId, userHash, 'dlv - login_status': loginStatus }
  }


  /**
   *
   */
  const pushNewsClickGA4 = (action, prevState, nextState) => {
    const itemData = GetGA4ItemData(nextState, action.id)
    itemData['dlv - ui_element'] = action.gaItemClickUiElement
    return GA4Event('news_click', nextState, itemData)
  }

  const pushNewsVoteGA4 = (action, prevState, nextState) => {
    const itemData = GetGA4ItemData(nextState, action.id)
    itemData['dlv - event_value'] = action.voteType
    return GA4Event('news_vote', nextState, itemData)
  }

  const pushLoadMoreNewsGA4 = (action, prevState, nextState) => {
    let list = '?'
    if (action.type === FETCH_NEXT_CATEGORY_ITEMS_SUCCESS) {
      list = action.category
    } else if (action.type === FETCH_NEXT_TAG_ITEMS_SUCCESS) {
      list = '#' + action.tag
    } else if (action.type === FETCH_NEXT_ITEMS_SUCCESS) {
      list = action.list
    } else if (action.type === FETCH_NEXT_USER_ITEMS_SUCCESS) {
      list = 'omat'
    }
    return GA4Event('load_more_news', nextState, {'dlv - event_value': list })
  }

  const pushSetListLanguageGA4 = (action, prevState, nextState) => {
    const langCode = action.value
    return GA4Event('set_list_language', nextState, {'dlv - event_value': langCode })
  }

  const pushSetPresetProfileGA4 = (action, prevState, nextState) => {
    const name = selectActivePresetProfileName(nextState)
    return GA4Event('set_preset_profile', nextState, {'dlv - event_value': name })
  }

  const pushSetNightModeGA4 = (action, prevState, nextState) => {
    const value = action.active ? 'on' : 'off'
    return GA4Event('set_dark_mode', nextState, {'dlv - event_value': value })
  }

  const pushNewsShareGA4 = (action, prevState, nextState) => {
    const itemData = GetGA4ItemData(nextState, action.id)
    itemData['dlv - event_value'] = action.channel
    return GA4Event('news_share', nextState, itemData)
  }

  // Temporary UA event for ads refresh
  const pushAdsRefreshUA = (action, prevState, nextState) => {
    return {
      event: 'ads-refresh',
    }
  }




  /**
   * All redux actions we listen to and their associated data/event formatters
   */
  const eventsMap = {}
  eventsMap[ITEM_CLICK] = pushNewsClickGA4
  eventsMap[ITEM_VOTE_SUCCESS] = pushNewsVoteGA4
  eventsMap[FETCH_NEXT_CATEGORY_ITEMS_SUCCESS] = pushLoadMoreNewsGA4
  eventsMap[FETCH_NEXT_ITEMS_SUCCESS] = pushLoadMoreNewsGA4
  eventsMap[FETCH_NEXT_TAG_ITEMS_SUCCESS] = pushLoadMoreNewsGA4
  eventsMap[FETCH_NEXT_USER_ITEMS_SUCCESS] = pushLoadMoreNewsGA4
  eventsMap[SET_ITEMS_LANGUAGE] = pushSetListLanguageGA4
  eventsMap[SET_ACTIVE_PRESET_PROFILE] = pushSetPresetProfileGA4
  eventsMap[SET_NIGHT_MODE_SUCCESS] = pushSetNightModeGA4
  eventsMap[ITEM_SHARE_INTENT] = pushNewsShareGA4


  eventsMap[LOCATION_CHANGE] = pushPageView
  eventsMap[SET_LOGGED_IN_USER] = pushUserId
  eventsMap[LOGOUT_USER_SUCCESS] = pushUserId

  eventsMap['ALMA_ADS_REFRESHED'] = pushAdsRefreshUA

  return createMiddleware(eventsMap, GoogleTagManager())
}
