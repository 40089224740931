import Immutable from 'immutable'


/** @return {?number} (null if no active preset profile) */
export const selectActivePresetProfileId = state => {
  const activeId = state.user.get('activePresetProfile')
  return state.user.getIn(['profiles', activeId, 'id'], null)
}

/** @return {string} */
export const selectActivePresetProfileName = state => {
  const activeId = state.user.get('activePresetProfile')
  return state.user.getIn(['profiles', activeId, 'name'], 'Näytä kaikki')
}

/** @return {Immutable.Set<number>} (empty set if no active preset profile) */
export const selectPresetBlacklistedCategoryIds = state => {
  const activeId = state.user.get('activePresetProfile')
  return state.user.getIn(['profiles', activeId, 'categoryBlacklist']) ?? Immutable.Set()
}
